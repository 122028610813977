import React from 'react';
import PropTypes from 'prop-types';
import {
  BOX_STATUS_NORMAL,
  BOX_STATUS_CORRECT,
  BOX_STATUS_WRONG
} from '../utils';

export default class PasswordBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDirty: false
    };

    this.changeIntercept = this.changeIntercept.bind(this);
  }

  changeIntercept(e) {
    this.setState({
      isDirty: true
    });
    this.props.changeHandler(e);
  }

  render() {
    const { label, value, status } = this.props;
    // const { isDirty } = this.state;

    let boxStyle;
    switch (status) {
    case BOX_STATUS_CORRECT:
      boxStyle = {
        borderWidth: '2px',
        borderColor: 'green'
      };
      break;
    case BOX_STATUS_WRONG:
      boxStyle = {
        borderWidth: '2px',
        borderColor: 'red'
      };
      break;
    case BOX_STATUS_NORMAL:
    default:
      boxStyle = {
        // borderWidth: '1px',
        // borderColor: 'black'
      };
      break;
    }

    return (
      <div>
        <label className="mt-4 text-gray-700 block">
          {label}
          <br />
          <input
            type="text"
            value={value}
            onChange={this.changeIntercept}
            style={boxStyle}
            className="my-1 px-1 rounded border-2 border-gray-500 outline-none focus:shadow-outline focus:bg-blue-100"
          />
          <div
            className="inline-block w-0 relative text-green-600"
            style={{ left: '-1.5rem' }}
          >
            {status === BOX_STATUS_CORRECT ? '✔️' : null}
          </div>
        </label>
      </div>
    );
  }
}

PasswordBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired
};
