import React from 'react';

import './LoadingEllipsis.css';

const LoadingEllipsis = () => (
  <>
    <span className="loadingEllipsis-one">.</span>
    <span className="loadingEllipsis-two">.</span>
    <span className="loadingEllipsis-three">.</span>
  </>
);

export default LoadingEllipsis;
