import React from 'react';
import PropTypes from 'prop-types';

function roundaboutThere(baseNumber) {
  const adjustment = Math.random();
  const sign = Math.random() > 0.5 ? 1 : 0;

  return baseNumber * (adjustment + sign);
}

class Counter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: props.initial
    };

    this.increment = this.increment.bind(this);

    setTimeout(this.increment, props.delay);
  }

  increment() {
    this.setState(prevState => ({
      count: prevState.count + 1
    }));

    if (!this.props.onlyIncrementOnce) {
      setTimeout(this.increment, roundaboutThere(this.props.delay));
    }
  }

  render() {
    return <span>{this.state.count.toLocaleString()}</span>;
  }
}

Counter.propTypes = {
  initial: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  onlyIncrementOnce: PropTypes.bool
};

Counter.defaultProps = {
  onlyIncrementOnce: false
};

export default Counter;
