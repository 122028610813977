import React from 'react';
import Counter from '../components/Counter';
import StatBox from '../components/StatBox';

export default class FactoryStats extends React.Component {
  render() {
    return (
      <div className="container">
        <h2 className="text-3xl text-blue-700 font-bold mb-8">
          Candy Production Stats
        </h2>

        <h3 className="text-xl text-blue-500 italic mb-8 md:mb-24">
          Attention Sweet Candy Factory Workers: Look how much we've done!
        </h3>

        <StatBox
          label="Candies produced today"
          count={<Counter initial={89372} delay={1500} />}
        />
        <StatBox
          label="Boxes of candy produced today"
          count={<Counter initial={2489} delay={3500} />}
        />
        <StatBox
          label="Crates of candy produced today"
          count={<Counter initial={151} delay={2000} onlyIncrementOnce />}
        />
        <StatBox
          label="Candies produced this week"
          count={<Counter initial={2901491} delay={1500} />}
        />
      </div>
    );
  }
}
