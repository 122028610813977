import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fileList } from '../fileList';
import LoadingEllipsis from '../components/LoadingEllipsis';
import { calculateHash } from '../utils';

const MAX_FILE_LIST_DISPLAYED = 150;

class EncryptScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filesEncrypted: false,
      fileIndex: 0
    };

    this._filesInterval = null;

    this.onEncryptButtonClick = this.onEncryptButtonClick.bind(this);
    this.renderSpinner = this.renderSpinner.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._filesInterval);
  }

  onEncryptButtonClick() {
    const encKey = prompt('ENCRYPTION KEY REQUIRED').toLocaleLowerCase();
    if (calculateHash(encKey) === 93838108) {
      this.setState({
        filesEncrypted: true
      });

      this._filesInterval = setInterval(() => {
        this.setState(prevState => ({
          fileIndex: Math.min(prevState.fileIndex + 1, MAX_FILE_LIST_DISPLAYED)
        }));
      }, 32);

      this.props.encryptedHandler();
    } else {
      alert('Incorrect encryption key. Failed to encrypt files.');
    }
  }

  onAlertAuthClick() {
    alert('🚨 Authorities have been alerted! 🚨');
  }
  onShutDownClick() {
    alert(
      '!! ⚠️ Cannot shut down server, production line is processing candy. ⚠️ !!'
    );
  }

  renderSpinner() {
    const { filesEncrypted, fileIndex } = this.state;

    if (!filesEncrypted) return <div className="h-48 py-8">&nbsp;</div>;

    const fl = fileList.slice(fileIndex, fileIndex + 5);
    const doneEl = this.renderDone();
    const boxStyle = doneEl ? { filter: 'blur(2px)' } : {};

    return (
      <div className="relative">
        <div
          className="font-mono bg-black text-white font-bold text-lg h-48 py-8"
          style={boxStyle}
        >
          <div className="inline-block w-1/2 text-right align-top pr-8">
            Encrypting files...
          </div>
          <div className="inline-block w-1/2 text-left pl-8">
            {fl.map(file => (
              <span key={file}>
                {file}
                <br />
              </span>
            ))}
          </div>
        </div>
        {doneEl}
      </div>
    );
  }

  renderDone() {
    if (this.state.fileIndex < MAX_FILE_LIST_DISPLAYED) return null;

    return (
      <div
        className="absolute h-full w-full top-0 left-0 flex justify-center items-center"
        style={{
          background: 'rgba(255,255,255,0.2'
        }}
      >
        <h3 className="text-white font-black text-3xl bg-red-800 px-2 py-1">
          ! FILES ENCRYPTED !<br />
          Redirecting to dashboard
          <LoadingEllipsis />
        </h3>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h2 className="text-3xl text-red-300 font-bold mb-16">
          Emergency Actions
        </h2>

        <div className="flex flex-col md:flex-row justify-center md:items-center px-4">
          <button
            type="button"
            className="text-2xl bg-red-600 border-2 border-red-500 hover:shadow-2xl hover:bg-red-700 px-16 py-8 text-white font-bold uppercase"
            onClick={this.onAlertAuthClick}
          >
            Alert Authorities
          </button>
          <button
            type="button"
            className="my-4 md:mx-16 text-2xl bg-red-600 border-2 border-red-500 hover:shadow-2xl hover:bg-red-700 px-16 py-8 text-white font-bold uppercase"
            onClick={this.onEncryptButtonClick}
          >
            Encrypt Files
          </button>
          <button
            type="button"
            className="text-2xl bg-red-600 border-2 border-red-500 hover:shadow-2xl hover:bg-red-700 px-16 py-8 text-white font-bold uppercase"
            onClick={this.onShutDownClick}
          >
            Shut Down
          </button>
        </div>

        {this.renderSpinner()}
      </div>
    );
  }
}

EncryptScreen.propTypes = {
  encryptedHandler: PropTypes.func.isRequired
};

export default EncryptScreen;
