import React from 'react';
import './App.css';
import FactoryStats from './screens/FactoryStats';
import LoginScreen from './screens/LoginScreen';
import { areBothPasswordsCorrect } from './utils';
import EncryptScreen from './screens/EncryptScreen';
import logo from './logo.png';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password1: '',
      password2: '',
      passwordsSubmitted: false,
      screen: 'login'
    };

    this.passwordChangeHandler = this.passwordChangeHandler.bind(this);
    this.passwordSubmitHandler = this.passwordSubmitHandler.bind(this);
    this.encryptedFilesHandler = this.encryptedFilesHandler.bind(this);
  }

  passwordChangeHandler(e, whichPassword) {
    this.setState({
      [whichPassword]: e.target.value,
      passwordsSubmitted: false
    });
  }

  passwordSubmitHandler(e) {
    e.preventDefault();

    this.setState({
      passwordsSubmitted: true
    });

    if (areBothPasswordsCorrect(this.state.password1, this.state.password2)) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.setState({
          screen: 'encrypt'
        });
      }, 3500);
    }
  }

  encryptedFilesHandler() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.setState({
        screen: 'stats'
      });
    }, 8500);
  }

  renderScreen() {
    const { password1, password2, passwordsSubmitted } = this.state;

    switch (this.state.screen) {
    case 'encrypt':
      return <EncryptScreen encryptedHandler={this.encryptedFilesHandler} />;
    case 'stats':
      return <FactoryStats />;
    case 'login':
    default:
      return (
        <LoginScreen
          password1={password1}
          password2={password2}
          isSubmitted={passwordsSubmitted}
          changeHandler={this.passwordChangeHandler}
          submitHandler={this.passwordSubmitHandler}
        />
      );
    }
  }

  render() {
    const { screen } = this.state;
    const isPublic = screen === 'stats';
    return (
      <div
        className={`App min-h-screen mx-auto flex flex-col justify-center ${
          isPublic ? 'bg-gray-100' : 'bg-gray-700'
        }`}
      >
        <div
          className={`${
            isPublic ? 'bg-gray-200' : 'bg-gray-800'
          } py-4 h-36 border-b border-red-400 shadow text-center`}
        >
          <img
            src={logo}
            alt="Sweet Candy Factory"
            className="h-24 inline-block"
          />
        </div>
        <div className="flex-1 flex flex-col justify-center">
          {this.renderScreen()}
        </div>
      </div>
    );
  }
}

export default App;
