import React from 'react';
import PropTypes from 'prop-types';

import PasswordBox from '../components/PasswordBox';
import { boxStatus, areBothPasswordsCorrect } from '../utils';
import LoadingEllipsis from '../components/LoadingEllipsis';

const Login = ({
  password1,
  password2,
  isSubmitted,
  changeHandler,
  submitHandler
}) => {
  const message =
    areBothPasswordsCorrect(password1, password2) && isSubmitted ? (
      <span className="text-green-700 mt-4 py-2 bg-green-100 border border-green-700 rounded-full">
        Passwords Accepted, Logging In
        <span className="font-bold">
          <LoadingEllipsis />
        </span>
      </span>
    ) : null;

  const loginButton = (
    <button
      type="submit"
      // onClick={submitHandler}
      className="rounded-full bg-red-700 hover:bg-red-500 font-bold text-white text-xl px-4 py-2 mt-8"
    >
      Log In
    </button>
  );

  return (
    <div className="bg-gray-200 w-64 self-center p-6 rounded-lg shadow-lg border border-orange-600">
      <h2 className="text-xl text-blue-700 font-bold mb-8">Server Login</h2>

      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
        onSubmit={submitHandler}
      >
        <PasswordBox
          value={password1}
          label="Password 1"
          status={boxStatus(password1, password2, false, isSubmitted)}
          changeHandler={e => {
            changeHandler(e, 'password1');
          }}
        />
        <PasswordBox
          value={password2}
          label="Password 2"
          status={boxStatus(password2, password1, true, isSubmitted)}
          changeHandler={e => {
            changeHandler(e, 'password2');
          }}
        />

        {message || loginButton}
      </form>
    </div>
  );
};

Login.propTypes = {
  password1: PropTypes.string.isRequired,
  password2: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired
};

export default Login;
