import React from 'react';

const StatBox = ({ label, count }) => {
  return (
    <div className="inline-block border-2 border-gray-300 bg-gray-200 shadow-lg text-center mx-8 mb-8 w-48 h-48">
      <div className="text-4xl text-gray-800 font-bold py-8">{count}</div>
      <div className="border-t-1 border-gray-100 bg-gray-500 text-gray-200 text-sm font-bold p-4 uppercase">
        {label}
      </div>
    </div>
  );
};

export default StatBox;
