export function calculateHash(input) {
  let hash = 0;
  if (!input || input.length === 0) return hash;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}

const password1Hash = 1521461131;
const password2Hash = -1456346748;

export function isPasswordCorrect(input) {
  const hash = calculateHash(input.toLowerCase());
  return hash === password1Hash || hash === password2Hash;
}

export function areBothPasswordsCorrect(password1, password2) {
  if (password1 !== password2) {
    return isPasswordCorrect(password1) && isPasswordCorrect(password2);
  }

  return false;
}

export const BOX_STATUS_CORRECT = 'bsCORRECT';
export const BOX_STATUS_WRONG = 'bsWRONG';
export const BOX_STATUS_NORMAL = 'bsNORMAL';
export function boxStatus(input, otherInput, isSecondInput, isSubmitted) {
  const isRightWrong = () => {
    // Correct password
    if (isSecondInput && input === otherInput) {
      return BOX_STATUS_WRONG;
    }

    return BOX_STATUS_CORRECT;
  };

  if (isSubmitted) {
    if (isPasswordCorrect(input)) {
      return isRightWrong();
    } else {
      return BOX_STATUS_WRONG;
    }
  } else {
    if (isPasswordCorrect(input)) {
      return isRightWrong();
    }
  }

  return BOX_STATUS_NORMAL;
}
